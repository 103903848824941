@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700|Open+Sans:400,400i,700,700i|Playfair+Display:400,400i,700,700i|Roboto:400,400i,700,700i|Ubuntu:400,400i,700,700i&display=swap&subset=cyrillic);
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans|Open+Sans+Condensed:300|Playfair+Display|Roboto|Ubuntu&display=swap'); */

.ApplyButton {
    flex-shrink: 0;
    margin-right: 5px;
    background-color: rgb(50, 205, 50);
}

.ApplyButton:hover {
    background-color: rgb(34, 139, 34);
}

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
    cursor: not-allowed;
}
.Resizer.disabled:hover {
    border-color: transparent;
}

.collapse-css-transition {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
}

body {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4em;
    margin: 0;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

.icon {
    width: 1em;
    height: 1em;
    will-change: transform;
}
.icon--expanded {
    -webkit-transform: rotateZ(-179.99deg);
            transform: rotateZ(-179.99deg);
}
.rotate90 {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
}
.image-wrapper {
    position: relative;
    max-width: 500px;
    height: 0;
    padding-bottom: 40%;
    margin-bottom: 1rem;
    background: #eee;
}
.image-wrapper__img {
    object-fit: cover;
    width: 100%;
    position: absolute;
}
.app {
    margin: 0 auto;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    padding: 3rem 1rem 1rem;
}
.app--transition-enabled .icon {
    transition: -webkit-transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}
.app__text {
    width: 100%;
}
.app__collapse {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.app__collapse--gradient .app__content {
    border-color: transparent;
}
.app__collapse--gradient:after {
    pointer-events: none;
    transition: opacity 300ms;
    will-change: opacity;
    opacity: 1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 50%, white);
}
.app__collapse--active:after {
    opacity: 0;
}
.app__content {
    border: 1px solid transparent;
    padding: 1rem 0;
    margin-bottom: 3rem;
}
.app__toggle :focus,
.app__toggle :active {
    border: 0 !important;
    outline: none !important;
}
.app__toggle::-moz-focus-inner {
    border: 0 !important;
}
.app__toggle {
    cursor: pointer;
    width: 100%;
    margin: 0 0;
    border: 1px solid transparent;
    text-transform: uppercase;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(198, 198, 198, 0.3);
    background-color: rgb(242, 242, 242);
    transition: background-color 300ms;
}
.app__toggle--active {
    background-color: #cdcdcd;
}
.app__toggle-text {
    font-size: inherit;
    display: flex;
    align-items: center;
}
.app__button {
    display: block;
    min-width: 20rem;
    max-width: 100%;
    margin: 0 auto;
}
.app__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
}

